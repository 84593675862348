import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash.throttle'

export default class Body extends Controller {
  static targets = ['navbar', 'navbarMenu']

  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    this.toggleClass = 'navbar__menu-active'
    this.scroll = throttle(this.scroll, 500)

    window.addEventListener('scroll', this.scroll, { passive: true })
  }

  disconnect () {
    window.removeEventListener('scroll', this.scroll)
  }

  navbarToggle () {
    this.element.classList.toggle('body-overflow')
    this.navbarMenuTarget.classList.toggle(this.toggleClass)
  }

  scroll () {
    const scrollPosition = window.scrollY

    if (scrollPosition === 0) {
      return this.navbarTarget.classList.remove('navbar-sliding_up')
    }

    if (scrollPosition < this.prevScrollYPos) {
      this.navbarTarget.classList.add('navbar-sliding_up')
    } else {
      this.navbarTarget.classList.remove('navbar-sliding_up')
    }

    this.prevScrollYPos = scrollPosition
  }
}
